import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Layout from "../../../components/Layout";
import Loading from "../../../components/Loading";
import services from "../../../services";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAward,
    faBuilding,
    faShieldAlt,
} from "@fortawesome/free-solid-svg-icons";
import ScreenImg from "../../../assets/imgs/consumidor/screen.jpg";
import TrustShield from "../../../assets/imgs/logo-trustsign-shield.png";

export default function SSLSeal() {
    const [load, setLoad] = useState(false);
    const [data, setData] = useState({});
    const [url, setUrl] = useState("");

    useEffect(() => {
        document.title = "Selo TrustSign - TrustSign";
        handleSeal();
    }, []);

    const handleSeal = async () => {
        setLoad(true);
        let url = new URL(document.location).searchParams.get("hostname");
        try {
            const data = await services.getSealInfo(url);
            setData(data);
            setUrl(url);
        } catch (error) {
            console.log(error);
            Swal.fire({
                title: "Erro!",
                text: "Ocorreu um erro ao enviar a mensagem!",
                icon: "error",
                confirmButtonText: "Fechar",
            });
        } finally {
            setLoad(false);
        }
    };

    return (
        <>
            <Layout type="pages">
                <div
                    className={`section-height-100 center-items ${
                        data.status_seal === "1"
                            ? "bg-dark-success-trust"
                            : "bg-dark-danger-trust"
                    }`}
                >
                    <Container>
                        <Row>
                            {data.status_seal === "1" ? (
                                <>
                                    <h2 className="text-center">
                                        Sua conexão com o site{" "}
                                        <strong className="text-break color-gray">
                                            {data.device}
                                        </strong>{" "}
                                        está{" "}
                                        <strong className="color-gray">
                                            PROTEGIDA
                                        </strong>
                                        .
                                    </h2>
                                </>
                            ) : (
                                <>
                                    <h2 className="text-center">
                                        Sua conexão com o site{" "}
                                        <strong className="text-break color-gray">
                                            {url}
                                        </strong>{" "}
                                        não está <strong>PROTEGIDA</strong>.
                                    </h2>
                                </>
                            )}
                        </Row>
                    </Container>
                </div>
                <Container className="py-5">
                    <Row>
                        <Col xs={12} md={6} className="mb-5 mb-md-0">
                            <h4 className="color-blue fst-italic text-center mb-5">
                                Detalhes do Certificado
                            </h4>
                            <Row className="align-items-center mb-4">
                                <Col
                                    sm={12}
                                    className="d-flex flex-row align-items-center"
                                >
                                    <div className="icon-seal me-3">
                                        <FontAwesomeIcon icon={faAward} />
                                    </div>
                                    <h6 className="color-dark m-0">
                                        <strong>Site Certificado</strong> <br />
                                        <span className="color-gray">
                                            {data.device ? data.device : "-"}
                                        </span>
                                    </h6>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-4">
                                <Col
                                    sm={12}
                                    className="d-flex flex-row align-items-center"
                                >
                                    <div className="icon-seal me-3">
                                        <FontAwesomeIcon icon={faBuilding} />
                                    </div>
                                    <h6 className="color-dark m-0">
                                        <strong>Razão Social</strong> <br />
                                        <span className="color-gray">
                                            {data.company_name
                                                ? data.company_name
                                                : "-"}
                                        </span>
                                    </h6>
                                </Col>
                            </Row>
                            <Row className="align-items-center mb-4">
                                <Col
                                    sm={12}
                                    className="d-flex flex-row align-items-center"
                                >
                                    <div className="icon-seal me-3">
                                        <FontAwesomeIcon icon={faShieldAlt} />
                                    </div>
                                    <h6 className="color-dark m-0">
                                        <strong>Status</strong> <br />
                                        <span className="color-gray">
                                            {data.expire_date ? (
                                                <>
                                                    Site certificado até {" "}
                                                    <strong>
                                                        {new Date(
                                                            data.expire_date
                                                        ).toLocaleDateString(
                                                            "pt-BR",
                                                            "DD/MM/YYYY"
                                                        )}
                                                    </strong>
                                                </>
                                            ) : (
                                                "-"
                                            )}
                                            .
                                        </span>
                                    </h6>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={6} className="border-start">
                            <h4 className="color-blue fst-italic text-center mb-5">
                                Visualizou o Selo SSL TrustSign? Então sua
                                conexão está projegida
                            </h4>
                            <Row className="text-center px-3">
                                <Col xs={12} md={6}>
                                    <h6 className="color-gray m-0">
                                        O Selo SSL TrustSign indica que a sua
                                        informação está protegida. O endereço da
                                        página que acessou deverá iniciar por um
                                        protocolo HTTPS:// e um cadeado ativo
                                        aparecendo no navegador
                                    </h6>
                                </Col>
                                <Col xs={12} md={6}>
                                    <h6 className="color-gray m-0">
                                        Estes indicadores garantem que a página
                                        acessada criptografa seus dados
                                        digitados e os transmite até os
                                        servidores do site por uma conexão
                                        segura.
                                    </h6>
                                </Col>
                                <Col xs={12} className="mt-4">
                                    <img
                                        src={ScreenImg}
                                        className="img-fluid"
                                        alt=""
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <div className="py-5 bg-dark-trust">
                    <Container>
                        <Row className="align-items-center">
                            <Col xs={12} md={2} className="text-center text-md-start mb-5 mb-md-0">
                                <img
                                    src={TrustShield}
                                    className="img-fluid"
                                    alt=""
                                />
                            </Col>
                            <Col xs={12} md={8}>
                                <h4 className="color-white fst-italic">
                                    O que a TrustSign faz por você
                                </h4>
                                <p>
                                    Somos empenhados no combate a
                                    vulnerabilidades em aplicações web,
                                    servidores e sistemas internos de empresas.
                                    A TrustSign é uma empresa de segurança que
                                    atua na prevenção, correção e gestão de
                                    riscos em ambientes corporativos. Oferecemos
                                    soluções que atendem a complexidade da
                                    segurança exigida nas corporações, porém com
                                    alternativas segmentadas para diferentes
                                    necessidades e com opções sob-demanda.
                                    <br />
                                    <br />
                                    Além de qualidade e credibilidade
                                    reconhecidas, o Selo TrustSign é o principal
                                    meio de identificação de segurança utilizado
                                    pelos usuários dos sites institucionais e
                                    bancários para saberem se estão ou não
                                    protegidos.
                                </p>
                                <Button
                                    className="mt-4 btn-white-border"
                                    onClick={() => window.location.href = "/"}
                                >
                                    Conheça os Serviços da TrustSign
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Layout>
            <Loading show={load} />
        </>
    );
}
