import {leadsApi, sealApi} from "../config/api";

const submitContact = async (data) => {
  try {
    const response = await leadsApi.post("/consumidor/forms/contato", data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getSealInfo = async (url) => {
  try {
    const response = await sealApi.get(`/domains/${url}/seal_image?brand=trustsign`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};


const services = {
  submitContact,
  getSealInfo
};
export default services;
