import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Table } from "react-bootstrap";
import Layout from "../../../components/Layout";
import Loading from "../../../components/Loading";
import { FormContact } from "../../../components/Forms";
import services from "../../../services";
import Swal from "sweetalert2";
import Slider from "react-slick";
import { ArrowButton } from "../../../components/Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClose } from "@fortawesome/free-solid-svg-icons";

export default function Pentest() {
    const [load, setLoad] = useState(false);

    useEffect(() => {
        document.title = "Pentest - TrustSign";
    });

    const handleSubmit = async (data) => {
        setLoad(true);
        try {
            await services.submitContact(data);
            window.location.href = "/sucesso/contato";
        } catch (error) {
            console.log(error);
            Swal.fire({
                title: "Erro!",
                text: "Ocorreu um erro ao enviar a mensagem!",
                icon: "error",
                confirmButtonText: "Fechar",
            });
        } finally {
            setLoad(false);
        }
    };

    const settingsDetailsSlider = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 2000,
        slidesToShow: 4,
        slidesToScroll: 1,

        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            }
        ]
    };

    const pentestLevels = [
        {
            feature: "Validação manual de falhas de injeção (injection)",
            values: [true, true, true],
        },
        {
            feature:
                "Validações manuais e automatizadas de Cross-Site Scripting (XSS)",
            values: [true, true, true],
        },
        {
            feature: "Validações automatizadas de erros na configuração",
            values: [true, true, true],
        },
        {
            feature:
                "Validação automatizada e manual das vulnerabilidades em cookies",
            values: [true, true, true],
        },
        {
            feature: "Validações automatizadas do certificado SSL",
            values: [true, true, true],
        },
        {
            feature: "Validações automatizadas dos headers de segurança",
            values: [true, true, true],
        },
        {
            feature: "Validação manual de vazamento de dados sensíveis",
            values: [true, true, true],
        },
        {
            feature: "Validação manual de autenticação e autorização",
            values: [true, true, true],
        },
        {
            feature:
                "Validação manual de referência insegura e direta a objetos",
            values: [true, true, true],
        },
        {
            feature:
                "Validação manual de redirecionamentos e encaminhamentos inválidos",
            values: [true, true, true],
        },
        {
            feature: "Validações manuais e automatizadas de LFI e RFI",
            values: [true, true, true],
        },
        {
            feature: "Validação manual procurando por falhas no fluxo lógico",
            values: [false, true, true],
        },
        {
            feature: "Validação manual de erros criptográficos na aplicação",
            values: [true, true, true],
        },
        {
            feature: "Validação manual de Cross-Site Request Forgery (CSRF)",
            values: [true, true, true],
        },
        {
            feature: "Validação das correções aplicadas (Reteste)",
            values: [false, true, true],
        },
        {
            feature:
                "Validações GreyBox em ambientes logados com usuário e senha",
            values: [false, false, true],
        },
    ];

    return (
        <>
            <Layout
                type="products"
                links={[
                    "visao-geral",
                    "descricao-detalhada",
                    "opcoes-solucao",
                    "tabela-descritiva",
                    "contato",
                ]}
            >
                <div
                    className="section-height-110 center-items parallax bg-pentest"
                    id="visao-geral"
                >
                    <Container>
                        <Row>
                            <Col>
                                <h1 className="text-start color-white fst-italic mb-4">
                                    Teste de <br />
                                    Invasão
                                </h1>
                                <p className="text-start color-white description">
                                    Teste de Invasão (Pentest) para encontrar
                                    vulnerabilidades de <br />
                                    segurança que as ferramentas automatizadas
                                    não identificam, com <br />
                                    emissão de relatório das correções a serem
                                    aplicadas.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div
                    className="padding-container-y parallax bg-pentest"
                    id="descricao-detalhada"
                >
                    <Container>
                        <Row>
                            <Col>
                                <Card className="px-5">
                                    <div className="my-5">
                                        <h4 className="fst-italic text-center color-blue mb-5">
                                            A proteção do ambiente das
                                            interfaces pelo Teste de Invasão
                                            pode ser feita no modelo GreyBox e
                                            BlackBox
                                        </h4>
                                        <hr />
                                        <h5 className="fst-italic text-center color-gray">
                                            O Teste de Invasão irá avaliar os
                                            controles adotados pelas
                                            corporações, analisando
                                            vulnerabilidades de segurança em
                                            aplicações, servidores ou sistemas
                                            internos das companhias, indo onde
                                            as ferramentas automatizadas não são
                                            capazes de alcançar.
                                            <br />
                                            As corporações exigem altos níveis
                                            de segurança para proteger
                                            informações internas e o
                                            armazenamento de dados de
                                            funcionários e clientes.
                                        </h5>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div
                    className="padding-container-y parallax bg-pentest"
                    id="opcoes-solucao"
                >
                    <Container>
                        <Row>
                            <Col className="p-2 solutions-slider" md={12}>
                                <Slider {...settingsDetailsSlider}>
                                    <div className="p-2">
                                        <Card className="card-solucoes">
                                            <Card.Header className="card-produtos-header header-gray d-flex justify-content-center align-items-center">
                                                <h4 className="mb-0 color-white fst-italic">
                                                    Aplicação
                                                </h4>
                                            </Card.Header>
                                            <Card.Body className="text-center">
                                                <h5 className="color-blue fst-italic my-5">
                                                    GreyBox
                                                </h5>
                                                <p className="color-gray mt-4 text-center">
                                                    Com acesso à informações
                                                    privilegiadas, como usuário
                                                    e senha.
                                                </p>
                                            </Card.Body>
                                            <Card.Footer className="border-0 mb-2 d-flex justify-content-center align-items-center">
                                                <ArrowButton
                                                    className="btn-blue-border"
                                                    icon="right"
                                                    onClick={() =>
                                                        (window.location.href =
                                                            "#contato")
                                                    }
                                                >
                                                    Entre em contato
                                                </ArrowButton>
                                            </Card.Footer>
                                        </Card>
                                    </div>
                                    <div className="p-2">
                                        <Card className="card-solucoes">
                                            <Card.Header className="card-produtos-header header-gray d-flex justify-content-center align-items-center">
                                                <h4 className="mb-0 color-white fst-italic">
                                                    Aplicação
                                                </h4>
                                            </Card.Header>
                                            <Card.Body className="text-center">
                                                <h5 className="color-blue fst-italic my-5">
                                                    BlackBox
                                                </h5>
                                                <p className="color-gray mt-4 text-center">
                                                    Sem acesso à informações
                                                    privilegiadas, como usuário
                                                    e senha.
                                                </p>
                                            </Card.Body>
                                            <Card.Footer className="border-0 mb-2 d-flex justify-content-center align-items-center">
                                                <ArrowButton
                                                    className="btn-blue-border"
                                                    icon="right"
                                                    onClick={() =>
                                                        (window.location.href =
                                                            "#contato")
                                                    }
                                                >
                                                    Entre em contato
                                                </ArrowButton>
                                            </Card.Footer>
                                        </Card>
                                    </div>
                                    <div className="p-2">
                                        <Card className="card-solucoes">
                                            <Card.Header className="card-produtos-header header-gray d-flex justify-content-center align-items-center">
                                                <h4 className="mb-0 color-white fst-italic">
                                                    Servidor
                                                </h4>
                                            </Card.Header>
                                            <Card.Body className="text-center">
                                                <h5 className="color-blue fst-italic my-5">
                                                    GreyBox
                                                </h5>
                                                <p className="color-gray mt-4 text-center">
                                                    Com acesso à informações
                                                    privilegiadas, como usuário
                                                    e senha.
                                                </p>
                                            </Card.Body>
                                            <Card.Footer className="border-0 mb-2 d-flex justify-content-center align-items-center">
                                                <ArrowButton
                                                    className="btn-blue-border"
                                                    icon="right"
                                                    onClick={() =>
                                                        (window.location.href =
                                                            "#contato")
                                                    }
                                                >
                                                    Entre em contato
                                                </ArrowButton>
                                            </Card.Footer>
                                        </Card>
                                    </div>
                                    <div className="p-2">
                                        <Card className="card-solucoes">
                                            <Card.Header className="card-produtos-header header-gray d-flex justify-content-center align-items-center">
                                                <h4 className="mb-0 color-white fst-italic">
                                                    Servidor
                                                </h4>
                                            </Card.Header>
                                            <Card.Body className="text-center">
                                                <h5 className="color-blue fst-italic my-5">
                                                    BlackBox
                                                </h5>
                                                <p className="color-gray mt-4 text-center">
                                                    Sem acesso à informações
                                                    privilegiadas, como usuário
                                                    e senha.
                                                </p>
                                            </Card.Body>
                                            <Card.Footer className="border-0 mb-2 d-flex justify-content-center align-items-center">
                                                <ArrowButton
                                                    className="btn-blue-border"
                                                    icon="right"
                                                    onClick={() =>
                                                        (window.location.href =
                                                            "#contato")
                                                    }
                                                >
                                                    Entre em contato
                                                </ArrowButton>
                                            </Card.Footer>
                                        </Card>
                                    </div>
                                    <div className="p-2">
                                        <Card className="card-solucoes">
                                            <Card.Header className="card-produtos-header header-gray d-flex justify-content-center align-items-center">
                                                <h4 className="mb-0 color-white fst-italic">
                                                    Interno
                                                </h4>
                                            </Card.Header>
                                            <Card.Body className="text-center">
                                                <h5 className="color-blue fst-italic my-5">
                                                    GreyBox
                                                </h5>
                                                <p className="color-gray mt-4 text-center">
                                                    Com acesso à informações
                                                    privilegiadas, como usuário
                                                    e senha.
                                                </p>
                                            </Card.Body>
                                            <Card.Footer className="border-0 mb-2 d-flex justify-content-center align-items-center">
                                                <ArrowButton
                                                    className="btn-blue-border"
                                                    icon="right"
                                                    onClick={() =>
                                                        (window.location.href =
                                                            "#contato")
                                                    }
                                                >
                                                    Entre em contato
                                                </ArrowButton>
                                            </Card.Footer>
                                        </Card>
                                    </div>
                                    <div className="p-2">
                                        <Card className="card-solucoes">
                                            <Card.Header className="card-produtos-header header-gray d-flex justify-content-center align-items-center">
                                                <h4 className="mb-0 color-white fst-italic">
                                                    Interno
                                                </h4>
                                            </Card.Header>
                                            <Card.Body className="text-center">
                                                <h5 className="color-blue fst-italic my-5">
                                                    BlackBox
                                                </h5>
                                                <p className="color-gray mt-4 text-center">
                                                    Sem acesso à informações
                                                    privilegiadas, como usuário
                                                    e senha.
                                                </p>
                                            </Card.Body>
                                            <Card.Footer className="border-0 mb-2 d-flex justify-content-center align-items-center">
                                                <ArrowButton
                                                    className="btn-blue-border"
                                                    icon="right"
                                                    onClick={() =>
                                                        (window.location.href =
                                                            "#contato")
                                                    }
                                                >
                                                    Entre em contato
                                                </ArrowButton>
                                            </Card.Footer>
                                        </Card>
                                    </div>
                                </Slider>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="padding-container-y parallax bg-pentest" id="tabela-descritiva">
                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <h4 className="my-5 fst-italic text-center">
                                        Teste de Invasão -{" "}
                                        <span className="fw-bold color-blue">
                                            Tabela descritiva
                                        </span>
                                        :
                                    </h4>
                                    <div className="table-description fs-16">
                                        <Table
                                            striped
                                            bordered
                                            hover
                                            className="mt-2 mb-5 table-description"
                                        >
                                            <thead className="fw-bold">
                                                <tr>
                                                    <th>Nível do Pentest</th>
                                                    <th>Nível 1</th>
                                                    <th>Nível 2</th>
                                                    <th>Nível 3</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {pentestLevels.map(
                                                    (row, rowIndex) => (
                                                        <tr key={rowIndex}>
                                                            <td>
                                                                {row.feature}
                                                            </td>
                                                            {row.values.map(
                                                                (
                                                                    value,
                                                                    valueIndex
                                                                ) => (
                                                                    <td
                                                                        key={
                                                                            valueIndex
                                                                        }
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon={
                                                                                value
                                                                                    ? faCheck
                                                                                    : faClose
                                                                            }
                                                                        />
                                                                    </td>
                                                                )
                                                            )}
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div
                    className="section-height-110 center-items parallax bg-pentest"
                    id="contato"
                >
                    <Container>
                        <Card className="card-contact">
                            <Row>
                                <Col
                                    xs={12}
                                    md={4}
                                    className="d-flex justify-content-center flex-column border-end"
                                >
                                    <h4 className="text-start color-white fst-italic mb-4">
                                        Fale Conosco
                                    </h4>
                                    <p className="text-start color-white">
                                        Entre em contato conosco para receber o
                                        contato de um de nossos consultores.
                                    </p>
                                </Col>
                                <Col xs={12} md={8} className="my-0 my-md-5">
                                    <FormContact onSubmit={handleSubmit} />
                                </Col>
                            </Row>
                        </Card>
                    </Container>
                </div>
            </Layout>
            <Loading show={load} />
        </>
    );
}
