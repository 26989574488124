import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Table } from "react-bootstrap";
import Layout from "../../../components/Layout";
import Loading from "../../../components/Loading";
import { FormContact } from "../../../components/Forms";
import services from "../../../services";
import Swal from "sweetalert2";
import Slider from "react-slick";
import { ArrowButton } from "../../../components/Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClose } from "@fortawesome/free-solid-svg-icons";

export default function WAF() {
    const [load, setLoad] = useState(false);

    useEffect(() => {
        document.title = "WAF - TrustSign";
    });

    const handleSubmit = async (data) => {
        setLoad(true);
        try {
            await services.submitContact(data);
            window.locatiom.href = "/sucesso/contato";
        } catch (error) {
            console.log(error);
            Swal.fire({
                title: "Erro!",
                text: "Ocorreu um erro ao enviar a mensagem!",
                icon: "error",
                confirmButtonText: "Fechar",
            });
        } finally {
            setLoad(false);
        }
    };

    const settingsSlider = {
        dots: true,
    };

    return (
        <>
            <Layout
                type="products"
                links={[
                    "visao-geral",
                    "descricao-detalhada",
                    "opcoes-solucao",
                    "tabela-descritiva",
                    "contato",
                ]}
            >
                <div
                    className="section-height-110 center-items parallax bg-waf"
                    id="visao-geral"
                >
                    <Container>
                        <Row>
                            <Col>
                                <h1 className="text-start color-white fst-italic mb-4">
                                    Web Application <br /> Firewall (WAF)
                                </h1>
                                <p className="text-start color-white description">
                                    Serviços de WAF (Web Application Firewall)
                                    para manter suas <br /> aplicações livres de
                                    ataques comuns, bloqueando acessos
                                    indesejados.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div
                    className="padding-container-y parallax bg-waf"
                    id="descricao-detalhada"
                >
                    <Container>
                        <Row>
                            <Col>
                                <Card className="px-5">
                                    <Slider
                                        {...settingsSlider}
                                        className="p-0 mb-4"
                                    >
                                        <div className="my-5">
                                            <h4 className="fst-italic text-center color-blue mb-5">
                                                O WAF é uma ferramenta
                                                responsável por controlar todas
                                                as requisições que estão sendo
                                                feitas à sua aplicação.
                                            </h4>
                                            <hr />
                                            <h5 className="fst-italic text-center color-gray">
                                                É um firewall que protege de
                                                ataques comuns como injeção SQL,
                                                CSS e DDoS, além do bloqueio de
                                                backdoors atuantes na porta HTTP
                                                e HTTPS e de acessos de bots.
                                            </h5>
                                        </div>
                                        <div className="my-5">
                                            <h4 className="fst-italic text-center color-blue mb-4">
                                                A partir de regras
                                                personalizáveis, também é
                                                possível
                                                <br />
                                                estabelecer qual tráfego será
                                                permitido ou bloqueado em
                                                <br />
                                                seu site, gerando economia de
                                                banda.
                                            </h4>
                                            <hr />
                                            <Row>
                                                <Col>
                                                    <p className="color-gray description-card">
                                                        Esta solução é flexível
                                                        para atender as
                                                        necessidades de clientes
                                                        no modelo Físico, onde o
                                                        cliente deverá instalar
                                                        um appliance em sua
                                                        infraestrutura e no
                                                        modelo SaaS (Cloud), que
                                                        não requer instalações.
                                                    </p>
                                                </Col>
                                                <Col>
                                                    <p className="color-gray description-card">
                                                        A solução em SaaS também
                                                        contempla o serviço de
                                                        CDN (Content Delivery
                                                        Network), ou seja, uma
                                                        rede de distribuição de
                                                        conteúdo global com
                                                        sistema avançado de
                                                        cache, proporcionando
                                                        uma economia de banda e
                                                        recursos do servidor.
                                                    </p>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Slider>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div
                    className="padding-container-y parallax bg-waf"
                    id="opcoes-solucao"
                >
                    <Container>
                        <Row>
                            <Col className="p-2">
                                <Card className="card-solucoes">
                                    <Card.Header className="card-produtos-header header-gray d-flex justify-content-center align-items-center">
                                        <h4 className="mb-0 color-white fst-italic">
                                            WAF
                                        </h4>
                                    </Card.Header>
                                    <Card.Body className="d-flex justify-content-center align-items-center">
                                        <h5 className="color-blue fst-italic my-5">
                                            WAF Cloud
                                        </h5>
                                    </Card.Body>
                                    <Card.Footer className="border-0 mb-2 d-flex justify-content-center align-items-center">
                                        <ArrowButton
                                            className="btn-blue-border"
                                            icon="right"
                                            onClick={() =>
                                                (window.locatiom.href =
                                                    "#contato")
                                            }
                                        >
                                            Entre em contato
                                        </ArrowButton>
                                    </Card.Footer>
                                </Card>
                            </Col>
                            <Col className="p-2">
                                <Card className="card-solucoes">
                                    <Card.Header className="card-produtos-header header-gray d-flex justify-content-center align-items-center">
                                        <h4 className="mb-0 color-white fst-italic">
                                            WAF
                                        </h4>
                                    </Card.Header>
                                    <Card.Body className="d-flex justify-content-center align-items-center">
                                        <h5 className="color-blue fst-italic my-5">
                                            WAF Físico
                                        </h5>
                                    </Card.Body>
                                    <Card.Footer className="border-0 mb-2 d-flex justify-content-center align-items-center">
                                        <ArrowButton
                                            className="btn-blue-border"
                                            icon="right"
                                            onClick={() =>
                                                (window.locatiom.href =
                                                    "#contato")
                                            }
                                        >
                                            Entre em contato
                                        </ArrowButton>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div
                    className="padding-container-y parallax bg-waf"
                    id="tabela-descritiva"
                >
                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <h4 className="my-5 fst-italic text-center">
                                        WAF -{" "}
                                        <span className="fw-bold color-blue">
                                            Tabela descritiva
                                        </span>
                                        :
                                    </h4>
                                    <div className="table-description">
                                        <Table
                                            striped
                                            bordered
                                            hover
                                            responsive
                                            className="mt-2 mb-5 table-description"
                                        >
                                            <thead className="fw-bold">
                                                <tr>
                                                    <th>Benefícios</th>
                                                    <th>
                                                        WAF Starter - Até 500gb
                                                        de tráfego
                                                    </th>
                                                    <th>
                                                        WAF PRO - Até 1tb de
                                                        tráfego
                                                    </th>
                                                    <th>
                                                        WAF Enterprise - Sob
                                                        consulta
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        Proteção contra bots
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>SQL Injection</td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Cross Site Scripting
                                                        (XSS)
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Acesso ilegal</td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Controle de acessos
                                                        (Blacklist)
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        WAF e SSL Padrão (MDC)
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Sistema de distribuição
                                                        de conteúdo - CDN
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>1 usuário no portal</td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Inclusão de arquivos e
                                                        backdoor
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faClose}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Suporte a IPV6, WAF e
                                                        SSL Padrão (MDC)
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faClose}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        3 usuários no portal
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faClose}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Report de Compliance do
                                                        PCI
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faClose}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faClose}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Utilização de regras
                                                        personalizadas
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faClose}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faClose}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Suporte a SSL
                                                        personalizado (próprio)
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faClose}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faClose}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Proteção contra DDoS
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faClose}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faClose}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Monitoramento 24x7</td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faClose}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faClose}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Suporte 24x7</td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faClose}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faClose}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Integração com SIEM</td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faClose}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faClose}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>SLA preferencial</td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faClose}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faClose}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Suporte a IPV6</td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faClose}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faClose}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div
                    className="padding-container-y center-items parallax bg-waf"
                    id="contato"
                >
                    <Container>
                        <Card className="card-contact">
                            <Row>
                                <Col
                                    xs={12}
                                    md={4}
                                    className="d-flex justify-content-center flex-column border-end"
                                >
                                    <h4 className="text-start color-white fst-italic mb-4">
                                        Fale Conosco
                                    </h4>
                                    <p className="text-start color-white">
                                        Entre em contato conosco para receber o
                                        contato de um de nossos consultores.
                                    </p>
                                </Col>
                                <Col xs={12} md={8} className="my-0 my-md-5">
                                    <FormContact onSubmit={handleSubmit} />
                                </Col>
                            </Row>
                        </Card>
                    </Container>
                </div>
            </Layout>
            <Loading show={load} />
        </>
    );
}
