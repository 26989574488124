import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Table } from "react-bootstrap";
import Layout from "../../../components/Layout";
import Loading from "../../../components/Loading";
import { FormContact } from "../../../components/Forms";
import services from "../../../services";
import Swal from "sweetalert2";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCheck,
    faClose,
    faStore,
    faWifi,
    faDatabase,
    faCreditCard,
} from "@fortawesome/free-solid-svg-icons";
import { ArrowButton } from "../../../components/Buttons";

export default function PCICompliance() {
    const [load, setLoad] = useState(false);

    useEffect(() => {
        document.title = "PCI Compliance - TrustSign";
    });

    const handleSubmit = async (data) => {
        setLoad(true);
        try {
            await services.submitContact(data);
            window.location.href = "/sucesso/contato";
        } catch (error) {
            console.log(error);
            Swal.fire({
                title: "Erro!",
                text: "Ocorreu um erro ao enviar a mensagem!",
                icon: "error",
                confirmButtonText: "Fechar",
            });
        } finally {
            setLoad(false);
        }
    };

    const settingsSlider = {
        dots: true,
    };

    return (
        <>
            <Layout
                type="products"
                links={[
                    "visao-geral",
                    "descricao-detalhada",
                    "opcoes-solucao",
                    "tabela-descritiva",
                    "contato",
                ]}
            >
                <div
                    className="section-height-110 center-items parallax bg-compliance"
                    id="visao-geral"
                >
                    <Container>
                        <Row>
                            <Col>
                                <h1 className="text-start color-white fst-italic mb-4">
                                    <span className="color-gray">
                                        Payment Card Industry
                                    </span>
                                    <br /> Data Security Standard
                                    <br />
                                    (PCI DSS).
                                </h1>
                                <p className="text-start color-white description">
                                    É o nome do conselho de segurança criado
                                    para garantir boas práticas <br />
                                    no uso, manuseio e armazenagem de dados de
                                    cartão de crédito.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div
                    className="padding-container-y parallax bg-compliance"
                    id="descricao-detalhada"
                >
                    <Container>
                        <Row>
                            <Col>
                                <Card className="px-5">
                                    <Slider {...settingsSlider} className="p-0">
                                        <div className="my-5">
                                            <h4 className="fst-italic text-center color-blue mb-5">
                                                O{" "}
                                                <span className="color-gray">
                                                    PCI Compliance
                                                </span>{" "}
                                                é uma
                                                <br />
                                                recomendação para:
                                            </h4>
                                            <Row className="text-center mb-5 slider-description">
                                                <Col>
                                                    <FontAwesomeIcon
                                                        icon={faStore}
                                                    />
                                                    <p className="color-gray">
                                                        Varejistas
                                                    </p>
                                                </Col>
                                                <Col>
                                                    <FontAwesomeIcon
                                                        icon={faWifi}
                                                    />
                                                    <p className="color-gray">
                                                        Provedores
                                                    </p>
                                                </Col>
                                                <Col>
                                                    <FontAwesomeIcon
                                                        icon={faDatabase}
                                                    />
                                                    <p className="color-gray">
                                                        Gateways
                                                    </p>
                                                </Col>
                                                <Col>
                                                    <FontAwesomeIcon
                                                        icon={faCreditCard}
                                                    />
                                                    <p className="color-gray">
                                                        Pagamentos
                                                    </p>
                                                </Col>
                                            </Row>
                                            <h5 className="fst-italic text-center color-blue">
                                                que{" "}
                                                <span className="color-gray">
                                                    coletam, armazenam e
                                                    transmitem
                                                </span>
                                                <br />
                                                informações de cartões de
                                                crédito.
                                            </h5>
                                        </div>
                                        <div className="my-5">
                                            <h4 className="fst-italic text-center color-blue mb-4">
                                                É orientado às empresas que
                                                efetuam transações
                                                <br /> pelo protocolo ATM <br />
                                                <span className="color-gray">
                                                    <strong>A</strong>
                                                    synchronous{" "}
                                                    <strong>
                                                        T
                                                    </strong>ransfer{" "}
                                                    <strong>M</strong>ode
                                                </span>
                                            </h4>
                                            <h4 className="fst-italic text-center color-blue mb-4">
                                                ou processam dados de cartões de
                                                crédito em nome de
                                                <br /> redes como:
                                            </h4>
                                            <h5 className="color-gray text-center fw-light">
                                                Visa, Mastercard, Discover
                                                Financial Services, American
                                                Express e JCB Internacional.
                                            </h5>
                                        </div>
                                    </Slider>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div
                    className="padding-container-y parallax bg-compliance"
                    id="opcoes-solucao"
                >
                    <Container>
                        <Row>
                            <Col className="p-2">
                                <Card className="card-solucoes">
                                    <Card.Header className="card-produtos-header header-gray d-flex justify-content-center align-items-center">
                                        <h4 className="mb-0 color-white fst-italic">
                                            nível 1
                                        </h4>
                                    </Card.Header>
                                    <Card.Body className="text-center">
                                        <h5 className="color-blue fst-italic my-5">
                                            {">"} 6 mi
                                        </h5>
                                        <p className="color-gray mt-4">
                                            de transações <br /> processadas.
                                        </p>
                                    </Card.Body>
                                    <Card.Footer className="border-0 mb-2 d-flex justify-content-center align-items-center">
                                        <ArrowButton
                                            className="btn-blue-border"
                                            icon="right"
                                            onClick={() =>
                                                (window.location.href =
                                                    "#contato")
                                            }
                                        >
                                            Entre em contato
                                        </ArrowButton>
                                    </Card.Footer>
                                </Card>
                            </Col>
                            <Col className="p-2">
                                <Card className="card-solucoes">
                                    <Card.Header className="card-produtos-header header-gray d-flex justify-content-center align-items-center">
                                        <h4 className="mb-0 color-white fst-italic">
                                            nível 2
                                        </h4>
                                    </Card.Header>
                                    <Card.Body className="text-center">
                                        <h5 className="color-blue fst-italic my-5">
                                            {">"} 1 - 6 mi
                                        </h5>
                                        <p className="color-gray mt-4">
                                            de transações <br /> processadas.
                                        </p>
                                    </Card.Body>
                                    <Card.Footer className="border-0 mb-2 d-flex justify-content-center align-items-center">
                                        <ArrowButton
                                            className="btn-blue-border"
                                            icon="right"
                                            onClick={() =>
                                                (window.location.href =
                                                    "#contato")
                                            }
                                        >
                                            Entre em contato
                                        </ArrowButton>
                                    </Card.Footer>
                                </Card>
                            </Col>
                            <Col className="p-2">
                                <Card className="card-solucoes">
                                    <Card.Header className="card-produtos-header header-gray d-flex justify-content-center align-items-center">
                                        <h4 className="mb-0 color-white fst-italic">
                                            nível 3
                                        </h4>
                                    </Card.Header>
                                    <Card.Body className="text-center">
                                        <h5 className="color-blue fst-italic my-5">
                                            {"<"} 1 mi
                                        </h5>
                                        <p className="color-gray mt-4">
                                            de transações <br /> processadas.
                                        </p>
                                    </Card.Body>
                                    <Card.Footer className="border-0 mb-2 d-flex justify-content-center align-items-center">
                                        <ArrowButton
                                            className="btn-blue-border"
                                            icon="right"
                                            onClick={() =>
                                                (window.location.href =
                                                    "#contato")
                                            }
                                        >
                                            Entre em contato
                                        </ArrowButton>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div
                    className="padding-container-y parallax bg-compliance"
                    id="tabela-descritiva"
                >
                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <h4 className="my-5 fst-italic text-center">
                                        PCI Compliance -{" "}
                                        <span className="fw-bold color-blue">
                                            Tabela descritiva
                                        </span>
                                        :
                                    </h4>
                                    <div className="table-description">
                                        <Table
                                            striped
                                            bordered
                                            hover
                                            responsive
                                            className="mt-2 mb-5 table-description"
                                        >
                                            <thead className="fw-bold">
                                                <tr>
                                                    <th>Nível</th>
                                                    <th>
                                                        Quantidade de transações
                                                        por ano
                                                    </th>
                                                    <th>
                                                        Auto Avaliação Anual
                                                    </th>
                                                    <th>Scan Trimestral</th>
                                                    <th>Auditoria Anual</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>Menos de 1 milhão</td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faClose}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>Entre 1 e 6 milhões</td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faClose}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>Mais de 6 milhões </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faClose}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FontAwesomeIcon
                                                            icon={faCheck}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div
                    className="padding-container-y parallax bg-compliance"
                    id="contato"
                >
                    <Container>
                        <Card className="card-contact">
                            <Row>
                                <Col
                                    xs={12}
                                    md={4}
                                    className="d-flex justify-content-center flex-column border-end"
                                >
                                    <h4 className="text-start color-white fst-italic mb-4">
                                        Fale Conosco
                                    </h4>
                                    <p className="text-start color-white">
                                        Entre em contato conosco para receber o
                                        contato de um de nossos consultores.
                                    </p>
                                </Col>
                                <Col xs={12} md={8} className="my-0 my-md-5">
                                    <FormContact onSubmit={handleSubmit} />
                                </Col>
                            </Row>
                        </Card>
                    </Container>
                </div>
            </Layout>
            <Loading show={load} />
        </>
    );
}
