import React from "react";
import {Route, Routes, Navigate} from "react-router-dom";

import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import PaginaSucesso from "./pages/Contact/Sucesso";
import PrivacyPolicy from "./pages/PrivacyPolicy";

// Consumidor
import SSLSeal from "./pages/Consumidor/SSLSeal"

// Products
import PCICompliance from "./pages/Products/PCICompliance";
import SSL from "./pages/Products/SSL";
import WAF from "./pages/Products/WAF";
import Pentest from "./pages/Products/Pentest";
import Incidents from "./pages/Products/Incidents";

const RootElement = () => (
  <Routes>
    <Route exact path="/" element={<Home/>}/>
    <Route exact path="/about" element={<About/>}/>
    <Route exact path="/contact" element={<Contact/>}/>
    <Route exact path="/sucesso/contato" element={<PaginaSucesso/>}/>
    <Route exact path="/privacy-policy" element={<PrivacyPolicy/>}/>

    {/* Products */}
    <Route exact path="/products/pci" element={<PCICompliance/>}/>
    <Route exact path="/products/ssl" element={<SSL/>}/>
    <Route exact path="/products/waf" element={<WAF/>}/>
    <Route exact path="/products/pentest" element={<Pentest/>}/>
    <Route exact path="/products/incidents" element={<Incidents/>}/>

    {/* Consumidor */}
    <Route exact path="/consumidor/selo-ssl" element={<SSLSeal/>}/>

    {/* 404 */}
    <Route path="*" element={<Navigate to="/"/>}/>
  </Routes>
);

export default RootElement;
