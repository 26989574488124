import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowUp, faArrowLeft, faArrowDown, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const ArrowButton = ({ onClick, ...props }) => {
    return (
        <Button
            className={`btn-action ${props.className}`}
            onClick={onClick}
        >
            {props.children}
            <FontAwesomeIcon
                icon={
                    (props.icon === "right" && faArrowRight) ||
                    (props.icon === "up" && faArrowUp) ||
                    (props.icon === "left" && faArrowLeft) ||
                    (props.icon === "down" && faArrowDown)
                }
                className="ms-3 fw-bold"
            />
        </Button>
    );
};

const ButtonSubmit = ({ onClick, ...props }) => {
    return (
        <Button
            className={`btn-action ${props.className}`}
            onClick={onClick}
            type="submit"
            disabled={props.disabled}
        >
            {props.children}
            <FontAwesomeIcon
                icon={faEnvelope}
                className="ms-3 fw-bold"
                size="xl"
            />
        </Button>
    );
};

export { ArrowButton, ButtonSubmit };