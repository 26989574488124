import React, { useEffect } from "react";
import { NavBarHome, NavBarPages, NavBarProducts } from "../Navbar";
import { Footer, FooterPages, FooterProducts } from "../Footer";

function Layout({ ...props }) {
    useEffect(() => {
        cwr(
            "recordPageView",
            window.location.pathname + window.location.search
        );
        if (window.top !== window.self) {
            window.top.location.href = window.location.href;
        }
    });
    const cwr = (operation, payload) => {};
    return (
        <>
            {props.type === "home" && <NavBarHome />}
            {props.type === "pages" && <NavBarPages />}
            {props.type === "products" && <NavBarProducts links={props.links} />}
            <div className="w-100 mx-auto overflow-auto">
                {props.children}
            </div>
            {props.type === "home" && <Footer />}
            {props.type === "pages" && <FooterPages />}
            {props.type === "products" && <FooterProducts />}
        </>
    );
}

export default Layout;
