import React, { useEffect, useState } from "react";
import { Navbar, Nav, Container, Row, Col } from "react-bootstrap";
import Logo from "../../assets/imgs/logo-trustsign.png";
import { ArrowButton } from "../../components/Buttons";

function NavBarHome() {
    const [activeLink, setActiveLink] = useState("home");
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        const onScroll = () => {
            const sections = ["home", "quem-somos", "solucoes", "contato"];
            const scrollPosition =
                document.documentElement.scrollTop || document.body.scrollTop;

            for (const section of sections) {
                const element = document.getElementById(section);
                if (element) {
                    const elementTop = element.offsetTop - 200;
                    const elementBottom =
                        elementTop + element.clientHeight + 200;

                    if (
                        scrollPosition >= elementTop &&
                        scrollPosition < elementBottom
                    ) {
                        setActiveLink(section);
                        break;
                    } else if (scrollPosition < 200) {
                        setActiveLink("home");
                    }
                }
            }
        };

        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    const handleScroll = () => {
        if (window.scrollY > 0) {
            document.querySelector(".navbar").classList.add("navbar-scroll");
        } else {
            document.querySelector(".navbar").classList.remove("navbar-scroll");
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <Navbar
            collapseOnSelect
            className={`fixed-top navbar-expand-lg ${
                isMenuOpen ? "navbar-expanded" : ""
            }`}
            expand="lg"
        >
            <Container fluid>
                <Row>
                    <Col
                        xs={6}
                        className="d-flex d-md-none justify-content-start align-items-center"
                    >
                        <a href="/">
                            <img src={Logo} className="img-fluid" alt="Logo" />
                        </a>
                    </Col>
                    <Col
                        xs={6}
                        className="d-flex justify-content-end align-items-center"
                    >
                        <Navbar.Toggle
                            aria-controls="responsive-navbar-nav"
                            onClick={toggleMenu}
                        />
                    </Col>
                </Row>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mx-auto d-flex align-items-center justify-content-between">
                        <Nav.Link
                            href="#home"
                            className={`btn-primary ${
                                activeLink === "home" ? "nav-active" : ""
                            }`}
                        >
                            Início
                        </Nav.Link>
                        <Nav.Link
                            href="#quem-somos"
                            className={`btn-primary ${
                                activeLink === "quem-somos" ? "nav-active" : ""
                            }`}
                        >
                            Quem Somos
                        </Nav.Link>
                        <Navbar.Brand
                            href="#home"
                            className="d-none d-lg-block mx-lg-auto"
                        >
                            <img src={Logo} className="img-fluid" alt="Logo" />
                        </Navbar.Brand>
                        <Nav.Link
                            href="#solucoes"
                            className={`btn-primary ${
                                activeLink === "solucoes" ? "nav-active" : ""
                            }`}
                        >
                            Soluções
                        </Nav.Link>
                        <Nav.Link
                            href="#contato"
                            className={`btn-primary ${
                                activeLink === "contato" ? "nav-active" : ""
                            }`}
                        >
                            Contato
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

function NavBarPages() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 0) {
            document.querySelector(".navbar").classList.add("navbar-scroll");
        } else {
            document.querySelector(".navbar").classList.remove("navbar-scroll");
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    });

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <Navbar
            collapseOnSelect
            className={`fixed-top navbar-expand-lg ${
                isMenuOpen ? "navbar-expanded" : ""
            }`}
            expand="lg"
        >
            <Container>
                <Row className="d-flex d-md-none">
                    <Col
                        xs={6}
                        className="d-flex d-md-none justify-content-start align-items-center"
                    >
                        <a href="/">
                            <img src={Logo} className="img-fluid" alt="Logo" />
                        </a>
                    </Col>
                    <Col
                        xs={6}
                        className="d-flex justify-content-end align-items-center"
                    >
                        <Navbar.Toggle
                            aria-controls="responsive-navbar-nav"
                            onClick={toggleMenu}
                        />
                    </Col>
                </Row>
                <Navbar.Collapse id="responsive-navbar-nav row">
                    <Col xs={12} md={6} className="d-none d-md-block">
                        <Navbar.Brand href="/">
                            <img src={Logo} className="img-fluid" alt="Logo" />
                        </Navbar.Brand>
                    </Col>
                    <Col
                        xs={12}
                        md={6}
                        className="d-flex justify-content-center justify-content-md-end align-items-center mt-4 mt-md-auto"
                    >
                        <ArrowButton
                            className="btn-blue-border"
                            onClick={() => (window.location.href = "/")}
                            icon="left"
                        >
                            Voltar para o início
                        </ArrowButton>
                    </Col>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

function NavBarProducts({ ...props }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [activeLink, setActiveLink] = useState(props.links[0]);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        const onScroll = () => {
            const sections = props.links;
            const scrollPosition =
                document.documentElement.scrollTop || document.body.scrollTop;

            for (const section of sections) {
                const element = document.getElementById(section);

                if (element) {
                    const elementTop = element.offsetTop - 100;
                    const elementBottom = elementTop + element.clientHeight;

                    if (
                        scrollPosition >= elementTop &&
                        scrollPosition < elementBottom
                    ) {
                        setActiveLink(section);
                        break;
                    } else if (scrollPosition < 100) {
                        setActiveLink(props.links[0]);
                    }
                }
            }

            window.addEventListener("scroll", onScroll);
            return () => window.removeEventListener("scroll", onScroll);
        };

        onScroll();
    }, [props.links]);

    const translateLinks = (link) => {
        switch (link) {
            case "home":
                return "início";
            case "quem-somos":
                return "quem somos";
            case "solucoes":
                return "soluções";
            case "contato":
                return "Contato";
            case "visao-geral":
                return "Visão Geral";
            case "descricao-detalhada":
                return "Descrição Detalhada";
            case "opcoes-solucao":
                return "Opções de Solução";
            case "tabela-descritiva":
                return "Tabela Descritiva";
            default:
                return link;
        }
    };

    return (
        <Navbar
            collapseOnSelect
            className={`fixed-top navbar-expand-lg ${
                isMenuOpen ? "navbar-expanded" : ""
            }`}
            expand="lg"
        >
            <Container>
                <Row className="d-flex d-md-none">
                    <Col
                        xs={6}
                        className="d-flex d-md-none justify-content-start align-items-center"
                    >
                        <a href="/">
                            <img src={Logo} className="img-fluid" alt="Logo" />
                        </a>
                    </Col>
                    <Col
                        xs={6}
                        className="d-flex justify-content-end align-items-center"
                    >
                        <Navbar.Toggle
                            aria-controls="responsive-navbar-nav"
                            onClick={toggleMenu}
                        />
                    </Col>
                </Row>
                <Navbar.Brand href="/" className="d-none d-md-block">
                    <img src={Logo} className="img-fluid" alt="Logo" />
                </Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mx-auto d-flex align-items-center justify-content-between">
                        {props.links.map((link, index) => {
                            return (
                                <Nav.Link
                                    key={index}
                                    href={`#${link}`}
                                    className={`btn-primary nav-products ${
                                        activeLink === link ? "nav-active" : ""
                                    }`}
                                >
                                    {translateLinks(link)}
                                </Nav.Link>
                            );
                        })}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export { NavBarHome, NavBarPages, NavBarProducts };
