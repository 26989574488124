import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Table } from "react-bootstrap";
import Layout from "../../../components/Layout";
import Loading from "../../../components/Loading";
import { FormContact } from "../../../components/Forms";
import services from "../../../services";
import Swal from "sweetalert2";
import Slider from "react-slick";
import { ArrowButton } from "../../../components/Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClose } from "@fortawesome/free-solid-svg-icons";

export default function SSL() {
    const [load, setLoad] = useState(false);

    useEffect(() => {
        document.title = "SSL - TrustSign";
    });

    const handleSubmit = async (data) => {
        setLoad(true);
        try {
            await services.submitContact(data);
            window.location.href = "/sucesso/contato";
        } catch (error) {
            console.log(error);
            Swal.fire({
                title: "Erro!",
                text: "Ocorreu um erro ao enviar a mensagem!",
                icon: "error",
                confirmButtonText: "Fechar",
            });
        } finally {
            setLoad(false);
        }
    };

    const settingsDetailsSlider = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 2000,
        slidesToShow: 4,
        slidesToScroll: 1,

        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            }
        ]
    };

    const settingsSlider = {
        dots: true,
    };

    const tableData = [
        {
            feature: "Barra verde de endereço",
            values: [
                false,
                false,
                true,
                false,
                false,
                true,
                false,
                false,
                false,
            ],
        },
        {
            feature: "Razão social na barra de endereço",
            values: [
                false,
                false,
                true,
                false,
                false,
                true,
                false,
                false,
                false,
            ],
        },
        {
            feature: "Cadeado verde na barra de endereço",
            values: [true, true, true, true, true, true, true, false, false],
        },
        {
            feature: "Chave de 2048 bits ou 4096 bits",
            values: [true, true, true, true, true, true, true, true, true],
        },
        {
            feature: "Nível de Validação",
            values: [true, true, true, true, true, true, true, true, true],
        },
        {
            feature: "Exibicação do Selo de Segurança",
            values: [true, true, true, true, true, true, true, false, false],
        },
        {
            feature: "Emissão emergencial",
            values: [true, true, true, true, true, true, true, true, true],
        },
        {
            feature: "Desenvolvido para MS Exchange",
            values: [
                false,
                false,
                false,
                true,
                false,
                false,
                false,
                false,
                false,
            ],
        },
        {
            feature: "Funciona para mais de 1 domínio",
            values: [
                false,
                false,
                false,
                true,
                true,
                true,
                false,
                false,
                false,
            ],
        },
        {
            feature: "Funciona para todos os subdomínios",
            values: [
                false,
                false,
                false,
                false,
                false,
                false,
                true,
                false,
                false,
            ],
        },
        {
            feature: "Portal para Gerenciamento dos Certificados",
            values: [true, true, true, true, true, true, true, true, false],
        },
        {
            feature: "Suporte em português desde a emissão até a instalação",
            values: [true, true, true, true, true, true, true, true, true],
        },
        {
            feature: "Reconhecimento dos navegadores de 99,9%",
            values: [true, true, true, true, true, true, true, true, true],
        },
        {
            feature: "Scan de SSL",
            values: [true, true, true, true, true, true, true, true, false],
        },
        {
            feature: "Reemissão ilimitada gratuita",
            values: [true, true, true, true, true, true, true, true, true],
        },
        {
            feature:
                "Compatível com Windows, Linux e outros Sistemas Operacionais",
            values: [true, true, true, false, true, true, true, true, false],
        },
        {
            feature:
                "Compatível com servidores web (Apache, IIS, Nginx, Tomcat, entre outros)",
            values: [true, true, true, false, true, true, true, true, false],
        },
        {
            feature: "Raiz de Certificação Internacional",
            values: [true, true, true, true, true, true, true, true, true],
        },
        {
            feature: "Responsável pela assinatura de aplicativos e softwares",
            values: [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                true,
                false,
            ],
        },
        {
            feature: "Responsável pela assinatura de e-mails",
            values: [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                true,
            ],
        },
    ];

    return (
        <>
            <Layout
                type="products"
                links={[
                    "visao-geral",
                    "descricao-detalhada",
                    "opcoes-solucao",
                    "tabela-descritiva",
                    "contato",
                ]}
            >
                <div
                    className="section-height-110 center-items parallax bg-ssl"
                    id="visao-geral"
                >
                    <Container>
                        <Row>
                            <Col>
                                <h1 className="text-start color-white fst-italic mb-4">
                                    Certificados <br />
                                    Digitais SSL
                                </h1>
                                <p className="text-start color-white description">
                                    Certificados digitais SSL (Secure Socker
                                    Layer) para aplicações <br /> web (simples e
                                    multidomínio), subdomínios, e-mails, códigos{" "}
                                    <br />
                                    fonte e aplicações do Microsoft Exchange.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div
                    className="padding-container-y parallax bg-ssl"
                    id="descricao-detalhada"
                >
                    <Container>
                        <Row>
                            <Col>
                                <Card className="px-5">
                                    <Slider
                                        {...settingsSlider}
                                        className="p-0 mb-4"
                                    >
                                        <div className="my-5">
                                            <h4 className="fst-italic text-center color-blue mb-5">
                                                <span className="color-gray">
                                                    Item básico de segurança
                                                </span>{" "}
                                                para todos os sites que realizam
                                                a transmissão de dados sensíveis
                                                como:
                                            </h4>
                                            <hr />
                                            <h5 className="fst-italic text-center color-gray">
                                                Os certificados Digitais SSL
                                                atuam para a criptografia destas
                                                informações, evitando que sejam
                                                interceptadas e interpretadas
                                                por alguém mal-intencionado.
                                            </h5>
                                        </div>
                                        <div className="my-5">
                                            <h4 className="fst-italic text-center color-blue mb-4">
                                                Certificados SSL podem
                                                contemplar{" "}
                                                <span className="color-gray">
                                                    diferentes necessidade de
                                                    sua empresa, que vão desde
                                                    certificados simples, até
                                                    soluções para múltiplos
                                                    domínios.
                                                </span>
                                            </h4>
                                            <hr />
                                            <Row>
                                                <Col xs={12} md={6}>
                                                    <p className="color-gray description-card">
                                                        Entre os certificados
                                                        oferecidos pela
                                                        TrustSign estão: SSL
                                                        Basic, SSL EV (Extended
                                                        Validation SSL) que faz
                                                        a checagem de CNPJ, e as
                                                        soluções multidomínio
                                                        SSL MDC (Multi Domain
                                                        SSL Certificate) e SSL
                                                        MDC EV (Multi Domain
                                                        Extended Validation).
                                                    </p>
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <p className="color-gray description-card">
                                                        Há ainda soluções SAN
                                                        (Subject Alternative
                                                        Name), focadas em
                                                        aplicações do Microsoft
                                                        Exchange, Wildcard, para
                                                        a proteção de
                                                        subdomínios, Codesign,
                                                        para códigos fonte e
                                                        S/MIME -
                                                        (Secure/Multipurpose
                                                        Internet Mail
                                                        Extensions) para
                                                        e-mails.
                                                    </p>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Slider>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div
                    className="padding-container-y parallax bg-ssl"
                    id="opcoes-solucao"
                >
                    <Container>
                        <Row>
                            <Col className="p-2 solutions-slider" md={12}>
                                <Slider {...settingsDetailsSlider}>
                                    <div className="p-2">
                                        <Card className="card-solucoes">
                                            <Card.Header className="card-produtos-header header-gray d-flex justify-content-center align-items-center">
                                                <h4 className="mb-0 color-white fst-italic">
                                                    SSL Basic
                                                </h4>
                                            </Card.Header>
                                            <Card.Body className="d-flex justify-content-center align-items-center">
                                                <p className="color-gray mt-4 text-center">
                                                    Criptografia informações
                                                    sensíveis de usuários
                                                </p>
                                            </Card.Body>
                                            <Card.Footer className="border-0 mb-2 d-flex justify-content-center align-items-center">
                                                <ArrowButton
                                                    className="btn-blue-border"
                                                    icon="right"
                                                    onClick={() =>
                                                        (window.location.href =
                                                            "#contato")
                                                    }
                                                >
                                                    Entre em contato
                                                </ArrowButton>
                                            </Card.Footer>
                                        </Card>
                                    </div>
                                    <div className="p-2">
                                        <Card className="card-solucoes">
                                            <Card.Header className="card-produtos-header header-gray d-flex justify-content-center align-items-center">
                                                <h4 className="mb-0 color-white fst-italic">
                                                    SSL EV
                                                </h4>
                                            </Card.Header>
                                            <Card.Body className="d-flex justify-content-center align-items-center">
                                                <p className="color-gray mt-4 text-center">
                                                    Além da criptografia de
                                                    informações sensíveis,
                                                    valida o CNPJ e exibe sua
                                                    razão social na url do site
                                                </p>
                                            </Card.Body>
                                            <Card.Footer className="border-0 mb-2 d-flex justify-content-center align-items-center">
                                                <ArrowButton
                                                    className="btn-blue-border"
                                                    icon="right"
                                                    onClick={() =>
                                                        (window.location.href =
                                                            "#contato")
                                                    }
                                                >
                                                    Entre em contato
                                                </ArrowButton>
                                            </Card.Footer>
                                        </Card>
                                    </div>
                                    <div className="p-2">
                                        <Card className="card-solucoes">
                                            <Card.Header className="card-produtos-header header-gray d-flex justify-content-center align-items-center">
                                                <h4 className="mb-0 color-white fst-italic">
                                                    MDC
                                                </h4>
                                            </Card.Header>
                                            <Card.Body className="d-flex justify-content-center align-items-center">
                                                <p className="color-gray mt-4 text-center">
                                                    Criptografia básica para
                                                    quem pretende proteger mais
                                                    de um domínio
                                                </p>
                                            </Card.Body>
                                            <Card.Footer className="border-0 mb-2 d-flex justify-content-center align-items-center">
                                                <ArrowButton
                                                    className="btn-blue-border"
                                                    icon="right"
                                                    onClick={() =>
                                                        (window.location.href =
                                                            "#contato")
                                                    }
                                                >
                                                    Entre em contato
                                                </ArrowButton>
                                            </Card.Footer>
                                        </Card>
                                    </div>
                                    <div className="p-2">
                                        <Card className="card-solucoes">
                                            <Card.Header className="card-produtos-header header-gray d-flex justify-content-center align-items-center">
                                                <h4 className="mb-0 color-white fst-italic">
                                                    MDC EV
                                                </h4>
                                            </Card.Header>
                                            <Card.Body className="d-flex justify-content-center align-items-center">
                                                <p className="color-gray mt-4 text-center">
                                                    Criptografia de validação
                                                    extendida para quem pretende
                                                    proteger mais de um domínio
                                                </p>
                                            </Card.Body>
                                            <Card.Footer className="border-0 mb-2 d-flex justify-content-center align-items-center">
                                                <ArrowButton
                                                    className="btn-blue-border"
                                                    icon="right"
                                                    onClick={() =>
                                                        (window.location.href =
                                                            "#contato")
                                                    }
                                                >
                                                    Entre em contato
                                                </ArrowButton>
                                            </Card.Footer>
                                        </Card>
                                    </div>
                                    <div className="p-2">
                                        <Card className="card-solucoes">
                                            <Card.Header className="card-produtos-header header-gray d-flex justify-content-center align-items-center">
                                                <h4 className="mb-0 color-white fst-italic">
                                                    UCC
                                                </h4>
                                            </Card.Header>
                                            <Card.Body className="d-flex justify-content-center align-items-center">
                                                <p className="color-gray mt-4 text-center">
                                                    Criptografia que protege
                                                    múltiplos domínios de
                                                    aplicações do Microsoft
                                                    Exchange e Office
                                                </p>
                                            </Card.Body>
                                            <Card.Footer className="border-0 mb-2 d-flex justify-content-center align-items-center">
                                                <ArrowButton
                                                    className="btn-blue-border"
                                                    icon="right"
                                                    onClick={() =>
                                                        (window.location.href =
                                                            "#contato")
                                                    }
                                                >
                                                    Entre em contato
                                                </ArrowButton>
                                            </Card.Footer>
                                        </Card>
                                    </div>
                                    <div className="p-2">
                                        <Card className="card-solucoes">
                                            <Card.Header className="card-produtos-header header-gray d-flex justify-content-center align-items-center">
                                                <h4 className="mb-0 color-white fst-italic">
                                                    Wildcard
                                                </h4>
                                            </Card.Header>
                                            <Card.Body className="d-flex justify-content-center align-items-center">
                                                <p className="color-gray mt-4 text-center">
                                                    Criptografia que protege
                                                    sub-domínios vinculados ao
                                                    domínio específico
                                                </p>
                                            </Card.Body>
                                            <Card.Footer className="border-0 mb-2 d-flex justify-content-center align-items-center">
                                                <ArrowButton
                                                    className="btn-blue-border"
                                                    icon="right"
                                                    onClick={() =>
                                                        (window.location.href =
                                                            "#contato")
                                                    }
                                                >
                                                    Entre em contato
                                                </ArrowButton>
                                            </Card.Footer>
                                        </Card>
                                    </div>
                                    <div className="p-2">
                                        <Card className="card-solucoes">
                                            <Card.Header className="card-produtos-header header-gray d-flex justify-content-center align-items-center">
                                                <h4 className="mb-0 color-white fst-italic">
                                                    Codesign
                                                </h4>
                                            </Card.Header>
                                            <Card.Body className="d-flex justify-content-center align-items-center">
                                                <p className="color-gray mt-4 text-center">
                                                    Criptografia de códigos
                                                    fonte de desenvolvedores web
                                                    com a inserção de uma
                                                    assinatura digital na
                                                    aplicação
                                                </p>
                                            </Card.Body>
                                            <Card.Footer className="border-0 mb-2 d-flex justify-content-center align-items-center">
                                                <ArrowButton
                                                    className="btn-blue-border"
                                                    icon="right"
                                                    onClick={() =>
                                                        (window.location.href =
                                                            "#contato")
                                                    }
                                                >
                                                    Entre em contato
                                                </ArrowButton>
                                            </Card.Footer>
                                        </Card>
                                    </div>
                                    <div className="p-2">
                                        <Card className="card-solucoes">
                                            <Card.Header className="card-produtos-header header-gray d-flex justify-content-center align-items-center">
                                                <h4 className="mb-0 color-white fst-italic">
                                                    S/MIME
                                                </h4>
                                            </Card.Header>
                                            <Card.Body className="d-flex justify-content-center align-items-center">
                                                <p className="color-gray mt-4 text-center">
                                                    Para e-mails com emissão de
                                                    assinatura digital que
                                                    atesta a veracidade da
                                                    mensagem conforme envio
                                                </p>
                                            </Card.Body>
                                            <Card.Footer className="border-0 mb-2 d-flex justify-content-center align-items-center">
                                                <ArrowButton
                                                    className="btn-blue-border"
                                                    icon="right"
                                                    onClick={() =>
                                                        (window.location.href =
                                                            "#contato")
                                                    }
                                                >
                                                    Entre em contato
                                                </ArrowButton>
                                            </Card.Footer>
                                        </Card>
                                    </div>
                                </Slider>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div
                    className="padding-container-y parallax bg-ssl"
                    id="tabela-descritiva"
                >
                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <h4 className="my-5 fst-italic text-center">
                                        SSL -{" "}
                                        <span className="fw-bold color-blue">
                                            Tabela descritiva
                                        </span>
                                        :
                                    </h4>
                                    <div className="table-description fs-16">
                                        <Table
                                            striped
                                            bordered
                                            hover
                                            responsive
                                            className="mt-2 mb-5 table-description"
                                        >
                                            <thead className="fw-bold">
                                                <tr>
                                                    <th>Tipos de SSL</th>
                                                    <th>Basic</th>
                                                    <th>Blindado</th>
                                                    <th>EV</th>
                                                    <th>SAN UCC</th>
                                                    <th>MDC</th>
                                                    <th>MDC EV</th>
                                                    <th>Wildcard</th>
                                                    <th>Codesign</th>
                                                    <th>S/MIME</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tableData.map(
                                                    (row, rowIndex) => (
                                                        <tr key={rowIndex}>
                                                            <td>
                                                                {row.feature}
                                                            </td>
                                                            {row.values.map(
                                                                (
                                                                    value,
                                                                    valueIndex
                                                                ) => (
                                                                    <td
                                                                        key={
                                                                            valueIndex
                                                                        }
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon={
                                                                                value
                                                                                    ? faCheck
                                                                                    : faClose
                                                                            }
                                                                        />
                                                                    </td>
                                                                )
                                                            )}
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div
                    className="section-height-110 center-items parallax bg-ssl"
                    id="contato"
                >
                    <Container>
                        <Card className="card-contact">
                            <Row>
                                <Col
                                    xs={12}
                                    md={4}
                                    className="d-flex justify-content-center flex-column border-end"
                                >
                                    <h4 className="text-start color-white fst-italic mb-4">
                                        Fale Conosco
                                    </h4>
                                    <p className="text-start color-white">
                                        Entre em contato conosco para receber o
                                        contato de um de nossos consultores.
                                    </p>
                                </Col>
                                <Col xs={12} md={8} className="my-0 my-md-5">
                                    <FormContact onSubmit={handleSubmit} />
                                </Col>
                            </Row>
                        </Card>
                    </Container>
                </div>
            </Layout>
            <Loading show={load} />
        </>
    );
}
