import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Layout from "../../../components/Layout";
import Loading from "../../../components/Loading";
import { FormContact } from "../../../components/Forms";
import services from "../../../services";
import Swal from "sweetalert2";
import { ArrowButton } from "../../../components/Buttons";

export default function Incidents() {
    const [load, setLoad] = useState(false);

    useEffect(() => {
        document.title = "Gestão de Incidentes - TrustSign";
    });

    const handleSubmit = async (data) => {
        setLoad(true);
        try {
            await services.submitContact(data);
            window.location.href = "/sucesso/contato";
        } catch (error) {
            console.log(error);
            Swal.fire({
                title: "Erro!",
                text: "Ocorreu um erro ao enviar a mensagem!",
                icon: "error",
                confirmButtonText: "Fechar",
            });
        } finally {
            setLoad(false);
        }
    };

    return (
        <>
            <Layout
                type="products"
                links={[
                    "visao-geral",
                    "descricao-detalhada",
                    "opcoes-solucao",
                    "contato",
                ]}
            >
                <div
                    className="section-height-110 center-items parallax bg-incident"
                    id="visao-geral"
                >
                    <Container>
                        <Row>
                            <Col>
                                <h1 className="text-start color-white fst-italic mb-4">
                                    Gestão de <br />
                                    Incidentes
                                </h1>
                                <p className="text-start color-white description">
                                    Monitoramento e Gestão de Incidentes capazes
                                    de prejudicar dados <br />
                                    confidenciais, bem como a reputação de seu
                                    negócio.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div
                    className="padding-container-y parallax bg-incident"
                    id="descricao-detalhada"
                >
                    <Container>
                        <Row>
                            <Col>
                                <Card className="px-5">
                                    <div className="my-5">
                                        <h4 className="fst-italic text-center color-blue mb-5">
                                            A{" "}
                                            <span className="color-gray">
                                                Gestão de incidentes
                                            </span>{" "}
                                            permite que as empresas façam o
                                            acompanhamento da segurança de suas
                                            plataformas e tenham visibilidade
                                            detalhada dos riscos aos quais seus
                                            ambientes estão submetidos.
                                        </h4>
                                        <hr />
                                        <h5 className="text-center color-gray fw-normal">
                                            A TrustSign auxilia as empresas no
                                            monitoramento e gestão de incidentes
                                            fornecendo uma plataforma gerencial
                                            com inúmeras possibilidades de
                                            integração. Com um atendimento 24x7,
                                            também auxiliamos as empresas na
                                            redução de riscos e impactos para o
                                            negócio, além do acompanhamento de
                                            novas ocorrências.
                                        </h5>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div
                    className="padding-container-y parallax bg-incident"
                    id="opcoes-solucao"
                >
                    <Container>
                        <Row>
                            <Col className="p-2">
                                <Card className="card-solucoes">
                                    <Card.Header className="card-produtos-header header-gray d-flex justify-content-center align-items-center">
                                        <h4 className="mb-0 color-white fst-italic">
                                            Serviço de Segurança Gerenciado
                                        </h4>
                                    </Card.Header>
                                    <Card.Body className="d-flex justify-content-center align-items-center">
                                        <h5 className="color-blue fst-italic my-5">
                                            Serviço responsável pela monitoração
                                            e gestão dos incidentes de forma
                                            remota.
                                        </h5>
                                    </Card.Body>
                                    <Card.Footer className="border-0 mb-2 d-flex justify-content-center align-items-center">
                                        <ArrowButton
                                            className="btn-blue-border"
                                            icon="right"
                                            onClick={() =>
                                                (window.location.href =
                                                    "#contato")
                                            }
                                        >
                                            Entre em contato
                                        </ArrowButton>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div
                    className="section-height-110 center-items parallax bg-incident"
                    id="contato"
                >
                    <Container>
                        <Card className="card-contact">
                            <Row>
                                <Col
                                    xs={12}
                                    md={4}
                                    className="d-flex justify-content-center flex-column border-end"
                                >
                                    <h4 className="text-start color-white fst-italic mb-4">
                                        Fale Conosco
                                    </h4>
                                    <p className="text-start color-white">
                                        Entre em contato conosco para receber o
                                        contato de um de nossos consultores.
                                    </p>
                                </Col>
                                <Col xs={12} md={8} className="my-0 my-md-5">
                                    <FormContact onSubmit={handleSubmit} />
                                </Col>
                            </Row>
                        </Card>
                    </Container>
                </div>
            </Layout>
            <Loading show={load} />
        </>
    );
}
