import React, { useEffect, useState } from "react";
import { Card, Row, Col, Button, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faArrowUp } from "@fortawesome/free-solid-svg-icons";

function Footer() {
    const [privacyPolicy, setPrivacyPolicy] = useState(true);

    useEffect(() => {
        if (localStorage.getItem("privacyPolicy") !== null) {
            setPrivacyPolicy(localStorage.getItem("privacyPolicy"));
        }
    }, []);

    const acceptPrivacyPolicy = () => {
        try {
            localStorage.setItem("privacyPolicy", false);
        } catch (error) {
            console.log(error);
        }
        setPrivacyPolicy(false);
    };

    return (
        <>
            <div className="footer bg-white">
                <Container>
                    <Row className="py-md-5 py-0">
                        <Col xs={12} md={6} className="d-flex d-md-block justify-content-center align-items-center mt-4 mt-md-0">
                            <Button className="btn-footer" onClick={() => window.location.href = "/privacy-policy"}>
                                Política de Privacidade{" "}
                                <FontAwesomeIcon
                                    icon={faArrowRight}
                                    className="ms-3 fw-bold"
                                />
                            </Button>
                        </Col>
                        <Col xs={12} md={6} className="text-end d-flex d-md-block justify-content-center align-items-center mb-4 mb-md-0 mt-3 mt-md-0">
                            <a href="#home">
                                <Button className="back-to-home">
                                    Voltar para o Início{" "}
                                    <FontAwesomeIcon
                                        icon={faArrowUp}
                                        className="ms-3"
                                    />
                                </Button>
                            </a>
                        </Col>
                    </Row>
                </Container>
            </div>
            {privacyPolicy === true && (
                <div className="fixed-bottom card-lgpd">
                    <Card>
                        <Row>
                            <Col xs={12} md={9}>
                                <small className="text-center">
                                    <strong>TrustSign e os cookies</strong>: a
                                    gente usa cookies para melhorar a sua
                                    experiência no site. Ao continuar navegando,
                                    você concorda com a nossa{" "}
                                    <a
                                        href="/privacy-policy"
                                        className="text-decoration-none"
                                        target="_blank"
                                    >
                                        <strong className="color-primary">
                                            Política de Privacidade
                                        </strong>
                                    </a>
                                    .
                                </small>
                            </Col>
                            <Col
                                xs={12}
                                md={3}
                                className="d-flex align-items-center justify-content-center"
                            >
                                <Button
                                    onClick={acceptPrivacyPolicy}
                                    className="btn-blue-dark"
                                >
                                    Continuar e fechar
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                </div>
            )}
        </>
    );
}

function FooterPages() {
    const [privacyPolicy, setPrivacyPolicy] = useState(true);

    useEffect(() => {
        if (localStorage.getItem("privacyPolicy") !== null) {
            setPrivacyPolicy(localStorage.getItem("privacyPolicy"));
        }
    }, []);

    const acceptPrivacyPolicy = () => {
        try {
            localStorage.setItem("privacyPolicy", false);
        } catch (error) {
            console.log(error);
        }
        setPrivacyPolicy(false);
    };

    return (
        <>
            {privacyPolicy === true && (
                <div className="fixed-bottom card-lgpd">
                    <Card>
                        <Row>
                            <Col xs={12} md={9}>
                                <small className="text-center">
                                    <strong>TrustSign e os cookies</strong>: a
                                    gente usa cookies para melhorar a sua
                                    experiência no site. Ao continuar navegando,
                                    você concorda com a nossa{" "}
                                    <a
                                        href="/privacy-policy"
                                        className="text-decoration-none"
                                        target="_blank"
                                    >
                                        <strong className="color-primary">
                                            Política de Privacidade
                                        </strong>
                                    </a>
                                    .
                                </small>
                            </Col>
                            <Col
                                xs={12}
                                md={3}
                                className="d-flex align-items-center justify-content-center"
                            >
                                <Button
                                    onClick={acceptPrivacyPolicy}
                                    className="btn-blue-dark"
                                >
                                    Continuar e fechar
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                </div>
            )}
        </>
    );
}

function FooterProducts() {
    const [privacyPolicy, setPrivacyPolicy] = useState(true);

    useEffect(() => {
        if (localStorage.getItem("privacyPolicy") !== null) {
            setPrivacyPolicy(localStorage.getItem("privacyPolicy"));
        }
    }, []);

    const acceptPrivacyPolicy = () => {
        try {
            localStorage.setItem("privacyPolicy", false);
        } catch (error) {
            console.log(error);
        }
        setPrivacyPolicy(false);
    };

    return (
        <>
            <div className="footer bg-white my-4 my-md-0">
                <Container>
                    <Row className="py-md-5 py-0">
                        <Col xs={12} className="text-end">
                            <a href="/">
                                <Button className="back-to-home">
                                    Voltar para o Início{" "}
                                    <FontAwesomeIcon
                                        icon={faArrowLeft}
                                        className="ms-3"
                                    />
                                </Button>
                            </a>
                        </Col>
                    </Row>
                </Container>
            </div>
            {privacyPolicy === true && (
                <div className="fixed-bottom card-lgpd">
                    <Card>
                        <Row>
                            <Col xs={12} md={9}>
                                <small className="text-center">
                                    <strong>TrustSign e os cookies</strong>: a
                                    gente usa cookies para melhorar a sua
                                    experiência no site. Ao continuar navegando,
                                    você concorda com a nossa{" "}
                                    <a
                                        href="/privacy-policy"
                                        className="text-decoration-none"
                                        target="_blank"
                                    >
                                        <strong className="color-primary">
                                            Política de Privacidade
                                        </strong>
                                    </a>
                                    .
                                </small>
                            </Col>
                            <Col
                                xs={12}
                                md={3}
                                className="d-flex align-items-center justify-content-center"
                            >
                                <Button
                                    onClick={acceptPrivacyPolicy}
                                    className="btn-blue-dark"
                                >
                                    Continuar e fechar
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                </div>
            )}
        </>
    );
}

export { Footer, FooterPages, FooterProducts };
