import axios from "axios";

const sbApi = axios.create({
  baseURL: process.env.REACT_APP_SB_API_URI,
});

const leadsApi = axios.create({
  baseURL: process.env.REACT_APP_SB_LEADS_URI,
});

const sealApi = axios.create({
  baseURL: process.env.REACT_APP_SB_SEAL_URI,
})

export {sbApi, leadsApi, sealApi};
