import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../../components/Layout";
import { ArrowButton } from "../../components/Buttons";

export default function About() {
    useEffect(() => {
        document.title = "Sobre - TrustSign";
    });

    return (
        <Layout type="pages">
            <div className="section-height-100 parallax bg-about-1">
                <Container className="d-flex align-items-center">
                    <Row>
                        <Col xs={12} md={9}>
                            <h3 className="color-white fst-italic mb-4">
                                A TrustSign{" "}
                                <span className="color-gray">
                                    faz parte do grupo da americanas s.a e é
                                    parceira da Site Blindado
                                </span>
                                , principal empresa de segurança para
                                e-commerces da América Latina.
                            </h3>
                            <p className="color-white description">
                                Oferecemos soluções em PCI Compliance,
                                Certificação Digital (SSL), Testes de Invasão,
                                WAF e Gestão de Riscos e Incidentes - com foco
                                total nas necessidades do seu negócio.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="section-height-100 parallax bg-about-2">
                <Container className="d-flex align-items-center">
                    <Row>
                        <Col xs={12} md={9}>
                            <h3 className="color-white fst-italic mb-4">
                                A TrustSign é uma empresa que segue os
                                <span className="color-gray">
                                    principais padrões de segurança
                                </span>{" "}
                                mundial como por exemplo a OWASP, NIST, CWE e
                                OVE.
                            </h3>
                            <p className="color-white description">
                                Nós contamos com uma equipe de especialistas,
                                tecnologias de ponta e as melhores diretrizes,
                                estabelecidas por comunidades internacionais, e
                                que visam fortalecer a gestão de segurança da
                                informação.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="section-height-100 parallax bg-about-3">
                <Container className="d-flex align-items-center">
                    <Row>
                        <Col xs={12} md={9}>
                            <h3 className="color-white fst-italic mb-4">
                                Aumente sua proteção conforme novas exigências e
                                demandas do negócio.{" "}
                                <span>
                                    Sua segurança é a nossa maior preocupação
                                </span>
                                .
                            </h3>
                            <p className="color-white description mb-4">
                                A TrustSign oferece soluções que atendem a
                                complexidade da segurança exigida nas
                                corporações, porém com alternativas segmentadas
                                para necessidades e com opções sob-demanda.
                            </p>
                            <p className="color-white description mb-4">
                                Personalize orçamentos e contratações conforme
                                sua necessidade.
                            </p>
                            <ArrowButton
                                className="btn-white-border"
                                icon="right"
                                onClick={() => window.location.href = "/contact"}
                            >
                                Entre em contato
                            </ArrowButton>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
}
