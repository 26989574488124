import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../../components/Layout";
import Loading from "../../components/Loading";
import { FormContact } from "../../components/Forms";
import services from "../../services";
import Swal from "sweetalert2";

export default function About() {
    const [load, setLoad] = useState(false);
    useEffect(() => {
        document.title = "Contato - TrustSign";
    });

    const handleSubmit = async (data) => {
        setLoad(true);
        try {
            await services.submitContact(data);
            window.location.href = "/sucesso/contato";
            
        } catch (error) {
            console.log(error);
            Swal.fire({
                title: "Erro!",
                text: "Ocorreu um erro ao enviar a mensagem!",
                icon: "error",
                confirmButtonText: "Fechar",
            });
        } finally {
            setLoad(false);
        }
    };

    return (
        <>
            <Layout type="pages">
                <div className="section-height-110 center-items parallax bg-contact-1">
                    <Container>
                        <Row className="pt-5 pt-md-0">
                            <Col
                                xs={12}
                                md={4}
                                className="d-flex justify-content-center flex-column border-end"
                            >
                                <h4 className="text-start color-white fst-italic mb-4">
                                    Fale Conosco
                                </h4>
                                <p className="text-start color-white">
                                    Entre em contato conosco para receber o
                                    contato de um de nossos consultores.
                                </p>
                            </Col>
                            <Col xs={12} md={8} className="my-0 my-md-5">
                                <FormContact onSubmit={handleSubmit} />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Layout>
            <Loading show={load} />
        </>
    );
}
