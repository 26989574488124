import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../../../components/Layout";
import ImgMsg from "../../../assets/imgs/contact/image.png";

export default function PaginaSucesso({ ...props }) {
    useEffect(() => {
        document.title = "Sucesso - TrustSign";
    });

    return (
        <>
            <Layout type="pages">
                <div className="section-height-110 center-items parallax bg-contact-1">
                    <Container>
                        <Row>
                            <Col
                                xs={12}
                                md={6}
                                className="d-flex justify-content-center flex-column text-start"
                            >
                                <h2 className="color-white fst-italic">Seu contato foi enviado com sucesso.</h2>
                                <p className="color-white fst-italic description"><strong>Ficamos felizes pelo seu interesse!</strong><br /> Logo, um de nossos especialistas entrará em contato para começarmos a construir a segurança da sua aplicação web, com tudo que você precisa!</p>
                            </Col>
                            <Col xs={12} md={6} className="my-0 my-md-5 text-end">
                                <img src={ImgMsg} className="img-fluid" alt="" />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Layout>
        </>
    );
}
