import React, {useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import {ButtonSubmit} from "../Buttons";

const FormContact = ({onSubmit, ...props}) => {
  const [item, setItem] = useState({});
  const [recaptcha, setRecaptcha] = useState(false);
  const recaptchaRef = React.createRef();

  const handleChange = (e) => {
    setItem({
      ...item,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (onSubmit) {
      onSubmit(item);
    }
  };

  return (
    <Form
      className="px-0 px-md-5 text-start"
      onSubmit={handleSubmit}
      {...props}
    >
      <Row>
        <Col xs={12}>
          <Form.Group className="form-group mb-3">
            <Form.Label className="color-white fst-italic fw-bold mb-0">
              Escreva seu nome aqui{" "}
              <sup className="ms-1 text-danger fw-bold">*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              name="name"
              required
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        <Col xs={12}>
          <Form.Group className="form-group mb-3">
            <Form.Label className="color-white fst-italic fw-bold mb-0">
              Escreva seu e-mail aqui{" "}
              <sup className="ms-1 text-danger fw-bold">*</sup>
            </Form.Label>
            <Form.Control
              type="email"
              name="email"
              required
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        <Col xs={12}>
          <Form.Group className="form-group mb-3">
            <Form.Label className="color-white fst-italic fw-bold mb-0">
              Escreva seu celular aqui{" "}
              <sup className="ms-1 text-danger fw-bold">*</sup>
            </Form.Label>
            <Form.Control
              type="number"
              name="cellPhone"
              required
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        <Col xs={12}>
          <Form.Group className="form-group mb-3">
            <Form.Label className="color-white fst-italic fw-bold mb-0">
              Escreva seu telefone aqui{" "}
            </Form.Label>
            <Form.Control type="number" name="phone" onChange={handleChange}/>
          </Form.Group>
        </Col>
        <Col xs={12}>
          <Form.Group className="form-group mb-3">
            <Form.Label className="color-white fst-italic fw-bold mb-0">
              CNPJ <sup className="ms-1 text-danger fw-bold">*</sup>
            </Form.Label>
            <Form.Control
              type="number"
              name="documentNumber"
              required
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        <Col xs={12}>
          <Form.Group className="form-group mb-3">
            <Form.Label className="color-white fst-italic fw-bold mb-0">
              Escreva sua mensagem aqui
            </Form.Label>
            <Form.Control
              as="textarea"
              name="message"
              rows={1}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={12} className="d-flex justify-content-center">
          <ReCAPTCHA
            className="mt-2"
            ref={recaptchaRef}
            sitekey={`6LdSA6AeAAAAANXbFRqmoL6Rl-IMBVoVnQmrU8GB`}
            onChange={() => setRecaptcha(true)}
            onError={() => setRecaptcha(false)}
            onExpired={() => setRecaptcha(false)}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col
          xs={12}
          md={6}
          className="d-flex align-items-center justify-content-center justify-content-md-start"
        >
          <p className="color-white fst-italic fw-bold mb-0">
            <sup className="me-1 text-danger fw-bold">*</sup> Campos
            obrigatórios
          </p>
        </Col>
        <Col
          xs={12}
          md={6}
          className="d-flex align-items-center justify-content-center justify-content-md-end mt-4 mt-md-0"
        >
          <ButtonSubmit
            className="btn-white-border"
            disabled={Object.keys(item).length < 4 || !recaptcha ? true : false}
          >
            Enviar
          </ButtonSubmit>
        </Col>
      </Row>
    </Form>
  );
};

export {FormContact};
